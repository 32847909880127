* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.error-message {
  color: #e74c3c;
  font-size: 16px;
  /* Add more CSS properties as needed */
}

html,
body {
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  text-align: center;
}

h1 {
  max-width: 100%;
  height: auto;
  margin: 10px 0; /* Adjust the top and bottom margins as needed */
  color: #3498db;
  font-family: 'Inter', sans-serif;
  font-size: 3em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0px;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 1); /* White text shadow */
}
.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/ccbg.png");
  background-size: cover;
  background-position: center top;
  z-index: -1;
  animation: scrollBackground 80s linear infinite;
}

@keyframes scrollBackground {
  0%, 100% {
    background-position: center top;
  }

  50% {
    background-position: center 100%;
  }
}


.menu {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #3498db;
  z-index: 2;
  border-bottom: 2px solid #2c3e50;
}

.menu ul {
  list-style: none;
  padding: 10px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.menu li {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}

/* Reduce the space between images and h1 */
img {
  margin-bottom: 5px; /* Adjust as needed */
}

/* Remove the white space below the menu */
body::before {
  content: "";
  display: block;
  height: 10px; /* Adjust to match the padding-top of the body */
}
